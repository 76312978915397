import linked from "./linked"

const filter = {
  init() {
    $(".xhr-filters").find("input, select").on("input", (e) => {
      this.xhr()
    })
  },

  xhr() {
    $.ajax({
      method: 'GET',
      url: window.location.href,
      data: this.filter_data(),
      success: (table) => {
        $('.xhr-append').html(table)
        linked.init()
      }
    })
  },

  filter_data() {
    let data = {}

    $(".xhr-filters").find("input, select").each((i,e) => {
      if($(e).attr("name") != undefined) {
        data[$(e).attr("name")] = $(e).val()
      }
    })

    return data
  }
}

export default filter